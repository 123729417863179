<template>
  <div>
    <app-collapse accordion class="mb-2 accordion-filters">
      <app-collapse-item title="Filtros de busca" class="mb-0">
        <b-form
          class="m-0 mt-1"
          ref="formFilter"
          @submit.prevent="submitFilter"
        >
          <div class="form-row">
            <b-col
              cols="12"
              md="4"
              class="align-items-center justify-content-left"
            >
              <div class="form-group">
                <label for="">Tag Tema</label>
                <v-select
                  ref="selectTema"
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="filters.tag_subject"
                  placeholder="Digite o título"
                  :options="optionsTagTema"
                  @search="fetchTagsCategoryTema"
                >
                  <span
                    slot="no-options"
                    @click="$refs.selectTema.open = false"
                  >
                    Nenhum registro encontrado
                  </span>
                </v-select>
              </div>
            </b-col>
            <b-col
              cols="6"
              md="4"
              class="d-none d-sm-block align-items-center justify-content-left"
            >
              <div class="form-group">
                <label for="">Tag Concurso</label>
                <v-select
                  ref="selectConcurso"
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="filters.tag_contest"
                  placeholder="Digite o título"
                  :options="optionsTagConcurso"
                  @search="fetchTagsCategoryConcurso"
                >
                  <span
                    slot="no-options"
                    @click="$refs.selectConcurso.open = false"
                  >
                    Nenhum registro encontrado
                  </span>
                </v-select>
              </div>
            </b-col>
            <b-col
              cols="6"
              md="4"
              class="d-none d-sm-block align-items-center justify-content-left"
            >
              <div class="form-group">
                <label for="">Tag Linha do tempo</label>
                <v-select
                  ref="selectLinhadoTempo"
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="filters.tag_timeline"
                  placeholder="Digite o título"
                  :options="optionsTagLinhaDoTempo"
                  @search="fetchTagsCategoryLinhaDoTempo"
                >
                  <span
                    slot="no-options"
                    @click="$refs.selectLinhadoTempo.open = false"
                  >
                    Nenhum registro encontrado
                  </span>
                </v-select>
              </div>
            </b-col>
          </div>
          <div class="form-row justify-content-between">
            <b-col cols="12" md="4">
              <div class="form-group">
                <label for="">Período de cadastro</label>
                <flat-pickr
                  v-model="filters.rangeDate"
                  class="form-control"
                  :config="{
                    mode: 'range',
                    altInput: true,
                    dateFormat: 'Y-m-d',
                    altFormat: 'd/m/Y',
                    locale: 'pt',
                  }"
                />
              </div>
            </b-col>
            <b-col cols="12" md="4">
              <div class="form-group">
                <label for="">Título</label>
                <input v-model="filters.title" class="form-control" />
              </div>
            </b-col>
            <b-col cols="12" md="4">
              <label for="" style="visibility: hidden">Buscar</label>
              <button
                type="submit"
                :disabled="submitedFilter"
                class="btn btn-primary btn-block"
              >
                <div v-if="submitedFilter">
                  <b-spinner label="Loading..." small /> Buscando...
                </div>
                <div v-else>
                  <feather-icon
                    icon="SearchIcon"
                    class="cursor-pointer cursor"
                    size="16"
                  />
                  Buscar
                </div>
              </button>
            </b-col>
          </div>
        </b-form>
      </app-collapse-item>
    </app-collapse>

    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-form-row class="justify-content-between">
          <b-col
            cols="4"
            md="2"
            class="d-none d-sm-block align-items-center justify-content-left"
          >
            <v-select
              label="title"
              required
              v-model="perPage"
              :options="perPageOptions"
              @input="setPerPageSelected"
              :clearable="false"
            />
          </b-col>
        </b-form-row>
      </div>

      <b-table
        sticky-header
        class="position-relative"
        :items="dataDisciplines"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Nenhum registro encontrado"
      >
        <template #cell(title)="data">
          {{ data.item.title }}
        </template>

        <template #cell(time)="data">
          <b-badge variant="light-dark">{{ data.item.time }}h</b-badge>
        </template>

        <template #cell(created_at)="data">
          {{ data.item.created_at | datePT(true) }}
        </template>

        <template #cell(actions)="data">
          <div class="d-flex-between">
            <feather-icon
              :id="`customer-${data.item.uuid}-delete-icon`"
              icon="TrashIcon"
              class="cursor-pointer cursor text-danger"
              size="16"
              @click="confirmDelete(data.item.uuid)"
            />

            <feather-icon
              :id="`customer-${data.item.uuid}-edit-icon`"
              icon="EditIcon"
              class="cursor-pointer cursor"
              size="16"
              @click="
                $router.push({
                  name: 'disciplines-edit',
                  params: { uuid: data.item.uuid },
                })
              "
            />
          </div>
        </template>
      </b-table>

      <CustomPaginateTable
        :rows-table="rowsTable"
        :current-page="currentPage"
        :per-page="perPage"
        :total-rows="totalRows"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BBadge,
  BPagination,
  BTooltip,
  BFormRow,
  BForm,
  BSpinner,
} from "bootstrap-vue";

import vSelect from "vue-select";

import { mapState } from "vuex";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";

import CustomPaginateTable from "@/views/components/custom/PaginationTable";

import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  components: {
    BFormInput,
    CustomPaginateTable,
    BCard,
    flatPickr,
    BForm,
    BRow,
    BCol,
    BButton,
    BTable,
    BBadge,
    BPagination,
    BTooltip,
    BFormRow,
    vSelect,
    AppCollapse,
    AppCollapseItem,
    BSpinner,
  },
  data() {
    return {
      submitedFilter: false,
      currentPage: 1,
      perPage: 100,
      totalRows: 0,
      rowsTable: 0,
      optionsTagTema: [],
      optionsTagLinhaDoTempo: [],
      optionsTagConcurso: [],
      perPageOptions: [
        {
          title: "100",
          value: 100,
        },
        {
          title: "200",
          value: 200,
        },
      ],
      dataDisciplines: [],
      tableColumns: [
        {
          key: "title",
          label: "Título",
        },
        {
          key: "time",
          label: "Horas",
          class: "text-center",
        },
        {
          key: "created_at",
          label: "Cadastrado",
          class: "text-center",
        },
        {
          key: "actions",
          label: "",
          class: "text-center",
          thStyle: "width: 80px",
        },
      ],
      filters: {
        tag_subject: [],
        tag_contest: [],
        tag_timeline: [],
        rangeDate: null,
        title: "",
      },
    };
  },
  created() {
    this.getData();
  },
  computed: {
    ...mapState("Tag", [
      "searchTagsTema",
      "searchTagsConcurso",
      "searchTagsLinhadoTempo",
    ]),
  },
  methods: {
    submitFilter() {
      this.getData();
    },
    async fetchTagsCategoryConcurso(term) {
      if (term.length > 2) {
        this.optionsTagConcurso = await this.$store.dispatch("Tag/searchTags", {
          category_id: 2,
          term: term,
        });
      }
    },
    async fetchTagsCategoryLinhaDoTempo(term) {
      if (term.length > 2) {
        this.optionsTagLinhaDoTempo = await this.$store.dispatch(
          "Tag/searchTags",
          { category_id: 3, term: term }
        );
      }
    },
    async fetchTagsCategoryTema(term) {
      if (term.length > 2) {
        this.optionsTagTema = await this.$store.dispatch("Tag/searchTags", {
          category_id: 1,
          term: term,
        });
      }
    },
    async getData() {
      this.submitedFilter = true;

      let filtersSearch = {
        tag_subject: this.filters.tag_subject
          ? this.filters.tag_subject.code
          : "",
        tag_contest: this.filters.tag_contest
          ? this.filters.tag_contest.code
          : "",
        tag_timeline: this.filters.tag_timeline
          ? this.filters.tag_timeline.code
          : "",
        title: this.filters.title ?? "",
        rangeDate: this.filters.rangeDate ?? "",
        ead: 1,
      };

      this.$store
        .dispatch("Discipline/all", {
          currentPage: this.currentPage,
          perPage: this.perPage,
          filters: filtersSearch,
        })
        .then((data) => {
          if (data) {
            this.dataDisciplines = data.data;
            this.rowsTable = data.data.length;
            this.totalRows = data.meta.total;
            this.currentPage = data.meta.current_page;
          }
        })
        .finally(() => {
          this.submitedFilter = false;
        });
    },
    setPerPageSelected(obj) {
      this.perPage = obj.value;
    },
    confirmDelete(uuid) {
      this.$swal({
        title: "Tem certeza?",
        text: "Só será possível deletar a aula caso não tenha nenhuma disciplina vinculada.",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero deletar!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("Discipline/delete", uuid).then(() => {
            this.getData();
            this.$swal({
              icon: "success",
              title: "Deletado!",
              text: "A aula foi deletada com sucesso.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          });
        }
      });
    },
  },
  beforeDestroy() {
    this.$root.$off("paginate:update");
  },
  mounted() {
    this.$root.$on("paginate:update", (res) => {
      this.currentPage = res._currentPage;
      this.totalRows = res._totalRows;
      this.rowsTable = res._rowsTable;
      this.perPage = res._perPage;
      this.getData();
    });
  },
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}

@import "@core/scss/vue/libs/vue-select.scss";
</style>
